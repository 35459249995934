/* You can add global styles to this file, and also import other style files */

/* fonts */
@import "https://fonts.googleapis.com/css?family=Staatliches";

/* bootstrap 4 */
/* @import url("https://cdn.jsdelivr.net/npm/bootstrap@4.5.0/dist/css/bootstrap.min.css"); */

/* bootstrap 5 */
@import "~bootstrap/dist/css/bootstrap.css";

@import "~font-awesome/css/font-awesome.css";

/* syntax highlight theme */
@import "~highlight.js/styles/tomorrow-night-bright.css";

/* bootsrtap 5 vs boostrap 4 diff */

/* https://stackoverflow.com/questions/68032602/bootstrap-4-to-bootstrap-5-migration-container-issue */
/* Fix to make bootsrtap .container class behave in bootstrap 5 like in bootsrtap 4 */
@media (min-width: 1400px) {
  .container {
    max-width: 1140px;
  }
}

/* https://stackoverflow.com/questions/50668594/remove-border-color-for-navbar-toggler-hamburger-icon-in-bootstrap-using-css */
/* Fix to remove the border for hamburger in bootsrtap 5 as in 4 it didn't show */
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
}

/* bootsrtap 5 vs boostrap 4 diff - end */

/* Bootstrap DatePicker */

.input-group-append {
  cursor: pointer;
}

/* notifications */

#toast-container > div {
  width: 100%;
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

html {
  background-color: #f5f5f5;
}

body {
  background-color: #f5f5f5;
  color: #4e4e4e;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* bootstrap tooltips */

.tooltip-inner {
  max-width: 100%;
}

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
/* body {
  background-color: #f5f5f5;
  color: #4e4e4e;
} */

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #1e1e1e;
  font-weight: 400 !important;
}

a {
  color: #1e1e1e;
  transition: all 0.5s ease-in-out;
}

a:hover {
  color: #0078ff;
  text-decoration: none;
  transition: all 0.5s ease-in-out;
}

.p-r {
  position: relative;
}

.color-a {
  color: #0078ff;
}

.color-d {
  color: #f5f5f5;
}

.color-text-a {
  color: #4e4e4e;
}

.box-shadow,
.paralax-mf,
.service-box,
.work-box,
.card-blog {
  box-shadow: 0 13px 8px -10px rgba(0, 0, 0, 0.1);
}

.box-shadow-a,
.button:hover {
  box-shadow: 0 0 0 4px #cde1f8;
}

.display-5 {
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-6 {
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.1;
}

.avatar {
  width: 32px;
  height: 32px;
  margin-right: 10px;
  overflow: hidden;
}

.nav-avatar {
  width: 32px;
  height: 32px;
  position: absolute;
  top: 3px;
}

.nav-avatar-placeholder {
  /* background-color: #0078ff; */
  width: 32px;
  height: 10px;
  margin-right: 10px;
  display: inline-block;
}

.bg-image {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center center;
}

@media (max-width: 1024px) {
  .bg-image {
    background-attachment: scroll;
  }
}

.overlay-mf {
  background-color: #0078ff;
}

.overlay-mf {
  position: absolute;
  top: 0;
  left: 0px;
  padding: 0;
  height: 100%;
  width: 100%;
  opacity: 0.7;
}

.paralax-mf {
  position: relative;
  padding: 8rem 0;
}

.display-table {
  width: 100%;
  height: 100%;
  display: table;
}

.table-cell {
  display: table-cell;
  vertical-align: middle;
}

/*--/ Sections /--*/
.sect-pt5 {
  padding-top: 5rem;
}

.sect-6 {
  padding: 6rem 0;
}

.sect-pt6 {
  padding-top: 6rem;
}

.sect-mt6 {
  margin-top: 6rem;
}

/*--/ Title s /--*/
.title-s {
  font-weight: 600;
  color: #1e1e1e;
  font-size: 1.1rem;
}

/*--/ Title A /--*/
.title-box {
  margin-bottom: 2rem;
}

.title-a {
  font-size: 2.7rem;
  font-weight: bold;
  text-transform: uppercase;
}

.subtitle-a {
  color: #4e4e4e;
}

.line-mf {
  width: 40px;
  height: 5px;
  background-color: #0078ff;
  margin: 0 auto;
}

/*--/ Title Left /--*/
.title-box-2 {
  margin-bottom: 2rem;
}

.title-left {
  font-size: 2rem;
  position: relative;
}

.title-left-underline {
  font-size: 2rem;
  position: relative;
}

.title-left-underline:before {
  content: "";
  position: absolute;
  height: 3px;
  background-color: #0078ff;
  width: 100px;
  bottom: -12px;
}

/*------/ Box /------*/
.box-pl2 {
  padding-left: 2rem;
}

.box-plr15 {
  padding-left: 15px;
  padding-right: 15px;
}

.box-shadow-full {
  padding: 2rem;
  position: relative;
  background-color: #fff;
  z-index: 2;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 3rem;
}

/*------/ Socials /------*/

.social-i {
  color: #949494;
  font-size: 20px !important;
  margin: auto 4px;
  transition: 0.3s;
  transition-property: color;
}

.social-i.linkedin {
  vertical-align: 2%;
  cursor: pointer;
}

.social-i.twitter {
  cursor: pointer;
}

.social-i:hover {
  color: #0078ff;
}

.socials-container {
  margin-left: -10px;
}

.socials {
  padding: 1.5rem 0;
}

.socials ul li {
  display: inline-block;
}

.socials .ico-circle {
  height: 40px;
  width: 40px;
  font-size: 1.7rem;
  border-radius: 50%;
  line-height: 1.4;
  margin: 0 15px 0 0;
  box-shadow: 0 0 0 3px #0078ff;
  transition: all 500ms ease;
}

.socials .ico-circle:hover {
  background-color: #0078ff;
  color: #fff;
  box-shadow: 0 0 0 3px #cde1f8;
  transition: all 500ms ease;
}

/*------/ Ul resect /------*/
.ul-resect,
.socials ul,
.list-ico,
.blog-wrapper .post-meta ul,
.box-comments .list-comments,
.widget-sidebar .list-sidebar,
.widget-tags ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.list-ico {
  line-height: 2;
}

.list-ico span {
  color: #0078ff;
  margin-right: 10px;
}

/*------/ Ico Circle /------*/
.ico-circle {
  height: 100px;
  width: 100px;
  font-size: 2rem;
  border-radius: 50%;
  line-height: 1.55;
  margin: 0 auto;
  text-align: center;
  box-shadow: 0 0 0 10px #0078ff;
  display: block;
}

/*------/ Owl Carousel /------*/
.owl-theme .owl-dots {
  text-align: center;
  margin-top: 18px;
}

.owl-theme .owl-dots .owl-dot {
  display: inline-block;
}

.owl-theme .owl-dots .owl-dot span {
  width: 18px;
  height: 7px;
  margin: 5px 5px;
  background: #cde1f8;
  border: 0px solid #cde1f8;
  display: block;
  transition: all 0.6s ease-in-out;
  cursor: pointer;
}

.owl-theme .owl-dots .owl-dot:hover span {
  background-color: #cde1f8;
}

.owl-theme .owl-dots .owl-dot.active span {
  background-color: #1b1b1b;
  width: 25px;
}

/*--/ Scrolltop s /--*/
.scrolltop-mf {
  position: relative;
  display: none;
}

.scrolltop-mf span {
  z-index: 999;
  position: fixed;
  width: 42px;
  height: 42px;
  background-color: #0078ff;
  opacity: 0.7;
  font-size: 1.6rem;
  line-height: 1.5;
  text-align: center;
  color: #fff;
  top: auto;
  left: auto;
  right: 30px;
  bottom: 50px;
  cursor: pointer;
  border-radius: 50%;
}

/*------/ Back to top button /------*/
.back-to-top {
  position: fixed;
  display: none;
  background: #0078ff;
  color: #fff;
  width: 44px;
  height: 44px;
  text-align: center;
  line-height: 1;
  font-size: 16px;
  border-radius: 50%;
  right: 15px;
  bottom: 15px;
  transition: background 0.5s;
  z-index: 11;
}

.back-to-top i {
  padding-top: 12px;
  color: #fff;
}

@media (max-width: 893px) {
  .back-to-top {
    bottom: 15px;
  }
}

/*------/ Prelaoder /------*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: #fff;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #f2f2f2;
  border-top: 6px solid #0078ff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: animate-preloader 1s linear infinite;
  animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*------/ Button /------*/
.button {
  display: inline-block;
  padding: 0.3rem 0.6rem;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1rem;
  border-radius: 0.3rem;
  border: 1px solid transparent;
  transition: all 500ms ease;
  cursor: pointer;
}

.button:focus {
  outline: 0;
}

.button:hover {
  background-color: #0062d3;
  color: #fff;
  transition: all 500ms ease;
}

.button-a {
  background-color: #0078ff;
  color: #fff;
  border-color: #cde1f8;
}

.button-big {
  padding: 0.9rem 2.3rem;
  font-size: 1.2rem;
}

.button-normal {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
}

.button-rouded {
  border-radius: 0.4rem;
}

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.checkbox-label {
  position: absolute;
  margin-left: 10px;
}

/*--------------------------------------------------------------
# Nav Bar
--------------------------------------------------------------*/
.navbar-b {
  transition: all 0.5s ease-in-out;
  background-color: transparent;
  /* padding-top: 1.563rem;
  padding-bottom: 1.563rem; */
}

.navbar-b.navbar-reduce {
  box-shadow: 0px 6px 9px 0px rgba(0, 0, 0, 0.06);
}

.navbar-b.navbar-trans.navbar-black {
  background-color: rgba(0, 0, 0, 0.85);
}

/* .navbar-b.navbar-trans .user {
  color: #fff;
} */

/* .user {
  color: #fff;
} */

.no-hover:hover {
  text-decoration: none;
}

.navbar-b.navbar-trans .nav-item,
.navbar-b.navbar-reduce .nav-item {
  position: relative;
  padding-right: 10px;
  padding-left: 0;
}

@media (min-width: 893px) {
  .navbar-b.navbar-trans .nav-item,
  .navbar-b.navbar-reduce .nav-item {
    padding-left: 10px;
  }
}

.navbar-b.navbar-trans .nav-link,
.navbar-b.navbar-reduce .nav-link {
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
}

.navbar-b.navbar-trans .nav-link:before,
.navbar-b.navbar-reduce .nav-link:before {
  content: "";
  position: absolute;
  margin-left: 0px;
  width: 0%;
  bottom: 0;
  left: 0;
  height: 2px;
  transition: all 500ms ease;
}

@media (min-width: 893px) {
  .navbar-b.navbar-trans .nav-link:before,
  .navbar-b.navbar-reduce .nav-link:before {
    margin-left: 18px;
  }
}

.navbar-b.navbar-trans .nav-link:hover,
.navbar-b.navbar-reduce .nav-link:hover {
  color: #1b1b1b;
}

.navbar-b.navbar-trans .nav-link:hover:before,
.navbar-b.navbar-reduce .nav-link:hover:before {
  width: 35px;
}

.navbar-b.navbar-trans .show > .nav-link:before,
.navbar-b.navbar-trans .active > .nav-link:before,
.navbar-b.navbar-trans .nav-link.show:before,
.navbar-b.navbar-trans .nav-link.active:before,
.navbar-b.navbar-reduce .show > .nav-link:before,
.navbar-b.navbar-reduce .active > .nav-link:before,
.navbar-b.navbar-reduce .nav-link.show:before,
.navbar-b.navbar-reduce .nav-link.active:before {
  width: 35px;
}

.navbar-b.navbar-trans .nav-link:before {
  background-color: #fff;
}

.navbar-b.navbar-trans .nav-link:hover {
  color: #fff;
}

.navbar-b.navbar-trans .show > .nav-link,
.navbar-b.navbar-trans .active > .nav-link,
.navbar-b.navbar-trans .nav-link.show,
.navbar-b.navbar-trans .nav-link.active {
  color: #fff;
}

.navbar-b.navbar-reduce {
  transition: all 0.5s ease-in-out;
  background-color: #fff;
  /* padding-top: 15px;
  padding-bottom: 15px; */
}

.navbar-b.navbar-reduce .nav-link {
  color: #0078ff;
}

.navbar-b.navbar-reduce .nav-link:before {
  background-color: #0078ff;
}

.navbar-b.navbar-reduce .nav-link:hover {
  color: #0078ff;
}

.navbar-b.navbar-reduce .show > .nav-link,
.navbar-b.navbar-reduce .active > .nav-link,
.navbar-b.navbar-reduce .nav-link.show,
.navbar-b.navbar-reduce .nav-link.active {
  color: #0078ff;
}

.navbar-b.navbar-reduce .navbar-brand {
  color: #0078ff;
}

.navbar-b.navbar-reduce .navbar-toggler span {
  background-color: #1b1b1b;
}

.navbar-b .navbar-brand {
  color: #fff;
  font-size: 1.6rem;
  font-weight: 600;
}

.navbar-b .navbar-nav .dropdown-item.show .dropdown-menu,
.navbar-b .dropdown.show .dropdown-menu,
.navbar-b .dropdown-btn.show .dropdown-menu {
  transform: translate3d(0px, 0px, 0px);
  visibility: visible !important;
}

.navbar-b .dropdown-menu {
  margin: 1.12rem 0 0;
  border-radius: 0;
}

.navbar-b .dropdown-menu .dropdown-item {
  padding: 0.7rem 1.7rem;
  transition: all 500ms ease;
}

.navbar-b .dropdown-menu .dropdown-item:hover {
  background-color: #0078ff;
  color: #fff;
  transition: all 500ms ease;
}

.navbar-b .dropdown-menu .dropdown-item.active {
  background-color: #0078ff;
}

/*--/ Hamburger Navbar /--*/
.navbar-toggler {
  position: relative;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0;
}

.navbar-toggler span {
  display: block;
  background-color: #fff;
  height: 3px;
  width: 25px;
  margin-top: 4px;
  margin-bottom: 4px;
  transform: rotate(0deg);
  left: 0;
  opacity: 1;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: transform 0.35s ease-in-out;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute;
  left: 12px;
  top: 10px;
  transform: rotate(135deg);
  opacity: 0.9;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px;
  visibility: hidden;
  background-color: transparent;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute;
  left: 12px;
  top: 10px;
  transform: rotate(-135deg);
  opacity: 0.9;
}

/*--------------------------------------------------------------
# Intro Section
--------------------------------------------------------------*/
.intro {
  height: 100vh;
  position: relative;
  color: #fff;
}

.intro .intro-content {
  text-align: center;
  position: absolute;
}

.intro .overlay-itro {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  left: 0px;
  padding: 0;
  height: 100%;
  width: 100%;
  opacity: 0.9;
}

.intro .intro-title {
  color: #fff;
  font-weight: 600 !important;
  font-size: 3rem;
}

@media (min-width: 893px) {
  .intro .intro-title {
    font-size: 4.5rem;
  }
}

.intro .intro-subtitle {
  font-size: 1.5rem;
  font-weight: 300;
}

@media (min-width: 893px) {
  .intro .intro-subtitle {
    font-size: 2.5rem;
  }
}

.intro .text-slider-items {
  display: none;
}

.intro-single {
  height: 350px;
}

.intro-single .intro-content {
  margin-top: 30px;
}

.intro-single .intro-title {
  text-transform: uppercase;
  font-size: 3rem;
}

@media (min-width: 893px) {
  .intro-single .intro-title {
    font-size: 3.5rem;
  }
}

.intro-single .breadcrumb {
  background-color: transparent;
  color: #0078ff;
}

.intro-single .breadcrumb .breadcrumb-item:before {
  color: #cde1f8;
}

.intro-single .breadcrumb .breadcrumb-item.active {
  color: #cde1f8;
}

.intro-single .breadcrumb a {
  color: #fff;
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/

.skill {
  margin-right: 10px;
  margin-bottom: 10px;
}

.skill-mf button.disabled {
  opacity: 1;
}

.skill-mf span {
  color: #4e4e4e;
}

.skill-mf .progress {
  background-color: #cde1f8;
  margin: 0.5rem 0 1.2rem 0;
  border-radius: 0;
  height: 0.7rem;
}

.skill-mf .progress .progress-bar {
  height: 0.7rem;
  background-color: #0078ff;
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.service-box {
  background-color: #fff;
  padding: 2.5rem 1.3rem;
  border-radius: 1rem;
  margin-bottom: 2rem;
}

.service-box:hover .ico-circle {
  transition: all 500ms ease;
  color: #fff;
  background-color: #0078ff;
  box-shadow: 0 0 0 10px #cde1f8;
}

.service-box .service-ico {
  margin-bottom: 1rem;
  color: #1e1e1e;
}

.service-box .ico-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 500ms ease;
  font-size: 3rem;
}

.service-box .s-title {
  font-size: 1.4rem;
  text-transform: uppercase;
  text-align: center;
  padding: 0.4rem 0;
}

.service-box .s-description {
  color: #4e4e4e;
}

/*--------------------------------------------------------------
# Counter
--------------------------------------------------------------*/
.counter-box {
  color: #fff;
  text-align: center;
}

@media (min-width: 577px) {
  .counter-box {
    margin-bottom: 1.8rem;
  }
}

.counter-ico {
  margin-bottom: 1rem;
}

.counter-ico .ico-circle {
  height: 60px;
  width: 60px;
  line-height: 1.8;
  box-shadow: 0 0 0 10px #cde1f8;
}

.counter-num .counter {
  font-size: 2rem;
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.work-card-container {
  overflow: hidden;
}

.work-box {
  margin-bottom: 3rem;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: #fff;
  position: relative;
}

.work-box:hover img {
  transform: scale(1.3);
}

.work-img {
  display: block;
  overflow: hidden;
}

.work-img img {
  transition: all 1s;
}

.work-content {
  padding: 2rem 3% 1rem 4%;
}

.work-content-text-over-card-container {
  margin-right: 0.92rem;
  margin-bottom: 0rem !important;
  position: absolute;
  top: 25%;
  text-align: center;
  width: 100%;
  color: black;
  text-shadow: 1px 1px 2px white, 0 0 25px white, 0 0 5px white;
  background: rgba(255, 255, 255, 0.5);
  /* color: white;
  text-shadow: 0.07em 0 black, 0 0.07em black, -0.07em 0 black, 0 -0.07em black;
  background: rgba(0, 0, 0, 0.5); */
}

.work-content-text-over-card-text {
  margin-bottom: 2px;
  padding: 0.5rem;
}

.work-content .w-more {
  color: #4e4e4e;
  font-size: 0.8rem;
}

.work-content .w-more .w-ctegory {
  color: #0078ff;
}

.work-content .w-like {
  font-size: 2.5rem;
  color: #0078ff;
  float: right;
}

.work-content .w-like a {
  color: #0078ff;
}

.work-content .w-like .num-like {
  font-size: 0.7rem;
}

.w-title {
  font-size: 1.2rem;
}

.work-card-container {
  margin-bottom: 3rem;
}

.work-card-content {
  height: 3.8rem;
  position: absolute;
  width: 100%;
  padding: 0.2rem 0rem 0rem 0.4rem;
  bottom: -50px;
  background-color: white;
  transition: 0.4s ease-in;
  overflow: hidden;
  box-shadow: 0 13px 18px -10px rgba(0, 0, 0, 0.1);
}

.imgBoxDiv {
  height: 270px;
}

.work-box:hover .work-card-content {
  height: calc(100% + 50px);
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {
  padding-top: 30px;
}

.portfolio-details .portfolio-details-container {
  position: relative;
}

.portfolio-details .portfolio-details-carousel {
  position: relative;
  z-index: 1;
}

.portfolio-details .portfolio-details-carousel .owl-nav,
.portfolio-details .portfolio-details-carousel .owl-dots {
  margin-top: 5px;
  text-align: left;
}

.portfolio-details .portfolio-details-carousel .owl-dot {
  display: inline-block;
  margin: 0 10px 0 0;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ddd !important;
}

.portfolio-details .portfolio-details-carousel .owl-dot.active {
  background-color: #0078ff !important;
}

.portfolio-details .portfolio-info {
  padding: 30px;
  position: absolute;
  right: 0;
  bottom: -70px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  z-index: 2;
}

.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.portfolio-details .portfolio-info ul li + li {
  margin-top: 10px;
}

.portfolio-details .portfolio-description {
  padding-top: 50px;
}

.portfolio-details .portfolio-description h2 {
  width: 50%;
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
  padding: 0 0 0 0;
}

@media (max-width: 893px) {
  .portfolio-details .portfolio-description h2 {
    width: 100%;
  }
  .portfolio-details .portfolio-info {
    position: static;
    margin-top: 30px;
  }
}

/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.testimonials .owl-carousel .owl-item img {
  width: auto;
}

.testimonial-box {
  color: #fff;
  text-align: center;
}

.testimonial-box .author-test {
  margin-top: 1rem;
}

.testimonial-box .author-test img {
  margin: 0 auto;
}

.testimonial-box .author {
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  margin: 1rem 0;
  display: block;
  font-size: 1.4rem;
}

.testimonial-box .comit {
  font-size: 2rem;
  color: #0078ff;
  background-color: #fff;
  width: 52px;
  height: 52px;
  display: block;
  margin: 0 auto;
  border-radius: 50%;
  line-height: 1.6;
}

@media (min-width: 893px) {
  .testimonial-box .description {
    padding: 0 5rem;
  }
}

@media (min-width: 992px) {
  .testimonial-box .description {
    padding: 0 8rem;
  }
}

@media (min-width: 1200px) {
  .testimonial-box .description {
    padding: 0 13rem;
  }
}

/*--------------------------------------------------------------
# InputForm
--------------------------------------------------------------*/
.footer-paralax {
  padding: 4rem 0 0 0;
}

.contact-mf {
  margin-top: 4rem;
}

@media (min-width: 767px) {
  .contact-mf .box-pl2 {
    margin-top: 3rem;
    padding-left: 0rem;
  }
}

/* Angular Validation - angular adds this ng-invalid, and also ng-touched if touched */

/* input.ng-invalid.ng-touched,
textarea.ng-invalid.ng-touched {
  border: 1px solid red;
} */

/* Angular Validation - end */

.input-form .error-message {
  color: #fff;
  background: #ed3c0d;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.input-form .form-group {
  padding: 5px 0px;
}

.input-form .submit-ok {
  /* display: none; */
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.input-form .loading {
  /* display: none; */
  background: #fff;
  text-align: center;
  padding: 15px;
}

.input-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.input-form button:not(.btn-secondary) {
  background: #0078ff;
  border: 0;
  padding: 0.55rem 0.9rem;
  color: #fff;
  transition: 0.4s;
}

.input-form button:not(.btn-secondary):hover {
  background: #3393ff;
}

.input-form button:disabled {
  background-color: #cccccc;
  color: #666666;
}

.input-form button:disabled:hover {
  background: #cccccc;
  box-shadow: 0 0 0 4px #f1f1f1;
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Blog
--------------------------------------------------------------*/
.blog-title {
  font-family: Staatliches;
  font-size: 4.4rem;
  font-weight: 400;
  color: #333;
}

.blog-content {
  margin-left: 2rem;
  margin-right: 2rem;
}

.blog-card-img {
  width: 100%;
  height: auto;
}

.blog-card-img-right {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.blog-card-body {
  padding: 0px 30px 50px 30px;
}

.blog-card-body-text {
  font-size: 1.5rem;
  line-height: 1.5;
}

@media (min-width: 767px) {
  .card-blog {
    margin-bottom: 3rem;
  }
}

.card-blog .card-body {
  position: relative;
}

.card-blog .card-category-box {
  position: absolute;
  text-align: center;
  top: -16px;
  left: 15px;
  right: 15px;
  line-height: 25px;
  overflow: hidden;
}

.card-blog .card-category {
  display: inline-block;
  color: #fff;
  padding: 0 15px 5px;
  overflow: hidden;
  background-color: #0078ff;
  border-radius: 4px;
}

.card-blog .card-category .category {
  color: #fff;
  display: inline-block;
  text-transform: uppercase;
  font-size: 0.7rem;
  letter-spacing: 0.1px;
  margin-bottom: 0;
}

.card-blog .card-title {
  font-size: 1.3rem;
  margin-top: 0.6rem;
}

.card-blog .card-description {
  color: #4e4e4e;
}

.card-blog .post-author {
  display: inline-block;
}

.card-blog .post-date {
  color: #4e4e4e;
  display: inline-block;
  /* float: right; */
}

/*--------------------------------------------------------------
# Blog Single
--------------------------------------------------------------*/
.certifications {
  padding-left: 14px;
}

.post-box,
.form-comments,
.box-comments,
.widget-sidebar {
  /* padding: 2rem; */
  background-color: #fff;
  /* margin-bottom: 3rem; */
}

/* @media (min-width: 893px) {
  .post-box,
  .form-comments,
  .box-comments,
  .widget-sidebar {
    padding: 3rem;
  }
} */

.blog-wrapper .article-title {
  font-size: 1.5rem;
}

@media (min-width: 893px) {
  .blog-wrapper .article-title {
    font-size: 1.9rem;
  }
}

.blog-wrapper .post-meta {
  margin: 1rem 0;
}

.blog-wrapper .post-meta ul {
  border-left: 4px solid #0078ff;
  margin-top: 1rem;
}

.blog-wrapper .post-meta ul li {
  display: inline-block;
  margin-left: 15px;
}

.blog-wrapper .post-meta ul a {
  color: #0078ff;
}

.blog-wrapper .post-meta ul span {
  color: #1e1e1e;
}

.blog-wrapper .blockquote {
  border-left: 4px solid #0078ff;
  padding: 18px;
  font-style: italic;
}

/*------/ Comments /------*/
.box-comments .list-comments li {
  padding-bottom: 40px;
}

.box-comments .list-comments .comment-avatar {
  display: table-cell;
  vertical-align: top;
}

.box-comments .list-comments .comment-avatar img {
  width: 80px;
  height: 80px;
}

.box-comments .list-comments .comment-image {
  display: table-cell;
  vertical-align: top;
}

.box-comments .list-comments .comment-image img {
  width: 180px;
  height: 180px;
}

.box-comments .list-comments .comment-author {
  font-size: 1.3rem;
}

@media (min-width: 893px) {
  .chat .box-comments .list-comments {
    font-size: 1.5rem;
    height: 40rem;
  }
}

@media (max-width: 893px) {
  .chat .box-comments .list-comments {
    font-size: 1.5rem;
    height: 27rem;
  }
}

.box-comments .list-comments .comment-details {
  display: table-cell;
  vertical-align: top;
  padding-left: 25px;
}

.box-comments .list-comments a {
  color: #0078ff;
}

.box-comments .list-comments span {
  color: #1e1e1e;
  font-weight: 300;
  /* font-style: italic; */
}

.box-comments .comment-children {
  margin-left: 40px;
}

/*------/ Sidebar /------*/
.widget-sidebar .sidebar-title {
  font-size: 1.6rem;
  font-weight: 600;
  border-left: 5px solid #0078ff;
  padding-left: 15px;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
}

.widget-sidebar .list-sidebar li {
  position: relative;
  padding: 6px 0 6px 24px;
}

.widget-sidebar .list-sidebar li:before {
  position: absolute;
  content: "";
  width: 10px;
  height: 1px;
  left: 0;
  background-color: #0078ff;
  top: 20px;
}

.sidebar-search input {
  background-color: #fff;
  border-radius: 0;
  transition: all 0.5s ease-in-out;
}

.sidebar-search .btn-search {
  background-color: #0078ff;
  border-color: #0078ff;
  border-radius: 0;
  padding-left: 20px;
  padding-right: 20px;
}

.widget-tags ul li {
  display: inline-block;
  background-color: #0078ff;
  padding: 0.2rem 0.6rem;
  margin-bottom: 0.5rem;
  border-radius: 15px;
}

.widget-tags ul li a {
  color: #fff;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
footer {
  text-align: center;
  color: #fff;
  padding: 25px 0;
  background: #0062d3;
}

footer .copyright {
  margin-bottom: 0.3rem;
}

footer .credits {
  margin-bottom: 0;
}

footer .credits a {
  color: #fff;
}

/*------------------------------------------------------------*/

.col-sm-12-no-margin {
  margin-right: -12px;
  margin-left: -12px;
}

/*--------------------------------------------------------------
# Admin - Sidebar
--------------------------------------------------------------*/

.sidebar-wrapper .list-group-item {
  width: 15rem;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  border-right: 1px solid rgba(0, 0, 0, 0.125);
  border-left: 1px solid rgba(0, 0, 0, 0.125);
}

.sidebar-wrapper .list-group-item:first-child {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.sidebar-wrapper .list-group-item:last-child {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

/* reset bootsrtap 5 defaults that makes admin sidebar items giggle when clicking on them */
/* this, below, is applied to all items except first, so that first will have the 1px border set above */
.sidebar-wrapper .list-group-item:not(.list-group-item:first-child) {
  margin-top: 0px !important;
  border-top-width: 0px !important;
}

.sidebar-wrapper .list-group-item:not(.active):hover {
  background-color: #dae0e5;
}

.sidebar-wrapper .sidebar-heading {
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
  font-size: 1.2rem;
}

/*--------------------------------------------------------------
# Admin - Pages
--------------------------------------------------------------*/

.admin-page-title {
  margin-bottom: 20px;
}

.button-new {
  padding-top: 0.8rem;
}

.img-edit {
  width: 20px;
  cursor: pointer;
}

.table-edit-column {
  width: 50px;
}

.table-edit-row {
  cursor: pointer;
}

/*--------------------------------------------------------------
# WysiwygEditor
--------------------------------------------------------------*/

.demo-editor {
  margin-top: -0.5rem;
  padding: 0.5rem;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
}

.rich-editor {
  padding: 0.1rem;
}
.rich-editor .rdw-editor-main {
  background-color: #fff;
}
.rich-editor .DraftEditor-editorContainer {
  padding: 0rem;
}
.draft-editor-wrapper {
  border: 1px solid #ccc;
}

.input-fullWidth {
  margin-left: 0px;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

.react-datepicker__input-container > input {
  padding-right: 15px;
  padding-left: 15px;
}

/*--------------------------------------------------------------
# Post Section
--------------------------------------------------------------*/
.post {
  position: relative;
  /* height: 100vh;  
  color: #fff; */
}

.post p {
  margin: 0px;
  padding: 0px;
}

.post .post-image-bg {
  background-image: none;
  background-color: rgba(0, 0, 0, 0.1);
  /* background-color: rgba(0, 0, 0, 0.5); */
  height: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.post .post-image {
  margin: 20px;
  height: 800px;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.post .post-title {
  color: #fff;
  font-family: Staatliches;
  font-size: 120px;
  /* text-shadow: 1px 0px 1px #000; */
}

@media (max-width: 893px) {
  .post .post-title {
    font-size: 3rem;
  }
}

.post .post-content {
  padding-bottom: 30px;
}

.post .post-content h1 {
  font-family: Staatliches;
  font-size: 4.4rem;
}

/* quill editor container - set styles from bootstrap - _reboot.scss */

.ql-editor {
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
}

.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  line-height: 1.2;
}

.ql-editor h1 {
  font-family: Staatliches;
  font-size: 4.4rem !important;
}

.ql-editor h2 {
  font-size: 2rem !important;
}

.ql-editor h4 {
  font-size: 1.5rem !important;
}

.ql-editor h5 {
  font-size: 1.25rem !important;
}

.ql-syntax {
  background-color: #23241f;
  padding: 0.5rem;
  color: #f8f8f2;
  overflow: visible;
}

/* ----- editor and viewer breaks */
/* https://timonweb.com/css/how-replace-line-break-tags-br-spaces-css/ */

/* .ql-editor br {
  content: "";
}

.ql-editor br::before {
  content: " ";
  display: block;
  height: 0px;
  margin-top: -8px;
}

.ql-editor p {
  margin-bottom: 1rem;
}

.post .post-content br {
  content: "";
}

.post .post-content br::before {
  content: " ";
}

.post .post-content p {
  margin-bottom: 1rem;
} */

/* ----- */

.ql-editor ul {
  margin-bottom: 1rem;
}

.post .container .ql-indent-1 {
  margin-left: 4.5em;
}

.ql-align-center {
  text-align: center;
}

.ql-syntax {
  overflow-x: auto;
}

/* quill editor container end */

/*--------------------------------------------------------------
# Chat
--------------------------------------------------------------*/

.chat-form .send-message-icon {
  margin-top: -6px;
  height: 20px;
}

.chat-form button[type="submit"] {
  background: #0078ff;
  border: 0;
  color: #fff;
  transition: 0.4s;
  width: 34px;
  height: 34px;
  margin-top: 2px;
  margin-left: 4px;
}

.chat-form button[type="submit"]:hover {
  background: #3393ff;
}

.chat-form button:disabled {
  background-color: #cccccc;
  color: #666666;
}

.chat-form button:disabled:hover {
  background: #cccccc;
  box-shadow: 0 0 0 4px #f1f1f1;
}

.chat-form img {
  margin-top: -9px;
  margin-left: -5px;
}

.chat .box-shadow-full {
  padding: 1.2rem;
}

.chat h5 {
  margin: 0px;
}

.chat p {
  margin: 0px;
}

.chat .header {
  margin-bottom: 10px;
}

.chat .box-comments .list-comments .comment-avatar img {
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
  margin-top: 6px;
}

.chat .box-comments .list-comments .comment-author {
  font-size: 15px;
  font-weight: 700;
}

.chat .box-comments .list-comments .comment-date {
  color: #606160;
  font-size: 13px;
  margin-left: 6px;
}

.chat .box-comments .list-comments .comment-message {
  font-size: 15px;
}

.chat .box-comments .list-comments .comment-details {
  padding-left: 4px;
}

.chat .box-comments .list-comments li {
  padding: 3px 2px;
}

.chat .dateStart p {
  font-size: 13px;
  height: 28px;
  line-height: 22px;
  border: 2px solid #c0c0c0;
  border-radius: 25px;
  padding: 0 16px;
  background-color: #fff;
}

.chat .dateStartLine {
  border-top: 1px solid #c0c0c0;
  height: 14px;
  margin-top: -14px;
}

.chat .chat-form {
  margin-top: 10px;
}

.div-overlay {
  pointer-events: none;
  opacity: 0.4;
}

/* https://kazzkiq.github.io/balloon.css/ config for tooltips */
[aria-label][data-balloon-pos] {
  cursor: auto !important; /* or any other value you want */
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/

.preloader {
  width: 0;
  height: 0;
}

.fadeIn {
  opacity: 1;
  width: 0;
  height: 0;
}

.fadeOut {
  opacity: 0;
  width: 0;
  height: 0;
  transition: opacity 0.7s;
}
